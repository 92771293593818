<template>
  <div id="app">
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/todo">Todo</router-link> |
      <router-link to="/tree">Tree</router-link> | -->
      <!-- <router-link to="/tree2">CMS</router-link> |
      <router-link to="/game-test">Game Play</router-link> -->
      <!-- <router-link to="/login">Login</router-link> |
      <router-link to="/register">Register</router-link> -->
    </div>
    <router-view/>
  </div>
</template>
<script>
export default {
  created: function () {
    this.$router.push('/')
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#nav {
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
