import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles.scss'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtm from 'vue-gtm'
import { BootstrapVue } from 'bootstrap-vue'

import { StickerSet } from '@/assets/emoji/list'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import UUID from 'vue-uuid'
import i18n from './i18n'

Vue.use(BootstrapVue)
Vue.use(UUID)
Vue.use(VueAxios, axios)
Vue.use(StickerSet)

Vue.config.productionTip = false
Vue.use(VueGtm, {
  id: 'GTM-K9256RW', // LIVE GTM ID
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
})

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate: async function () {
    await this.$store.dispatch('init')
  }
}).$mount('#app')

Vue.prototype.$StickerSet = StickerSet

/**
 * register event bus
 */
Vue.prototype.$bus = new Vue()

Vue.prototype.$score_format = function (x) {
  const gameVersion = app.$store.state.gameSetting.config.version
  let separator = '.'
  if (gameVersion === 'sri') {
    separator = ','
  }
  if (!x) {
    x = 0
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
}

/**
 * register $data_get
 */
Vue.prototype.$data_get = function (object, key, defaultValue = null) {
  const keys = Array.isArray(key) ? key : key.split('.')

  for (let i = 0; i < keys.length; i++) {
    const currentKey = keys[i]

    if (typeof object !== 'object' || object === null) {
      return defaultValue
    }

    if (currentKey.includes('[') && currentKey.endsWith(']')) {
      // If the key contains array notation, split it into the array name and index
      const [arrayName, indexString] = currentKey.split(/[[\]]/)
      const index = parseInt(indexString)

      // Navigate to the array and the index
      object = object[arrayName]
      object = Array.isArray(object) ? object[index] : undefined
    } else {
      // Otherwise, navigate to the object property with the current key
      object = object[currentKey]
    }

    if (typeof object === 'undefined') {
      return defaultValue
    }
  }
  return object
}

Vue.prototype.$formatMessage = function (str) {
  if (str) {
    str = str.replaceAll('[blue]', '<span class="blue">')
    str = str.replaceAll('[/blue]', '</span>')

    str = str.replaceAll('[link]', '<span class="link">')
    str = str.replaceAll('[/link]', '</span>')

    str = str.replaceAll('[i]', '<i>')
    str = str.replaceAll('[/i]', '</i>')

    str = str.replaceAll('[b]', '<strong>')
    str = str.replaceAll('[/b]', '</strong>')

    str = str.replaceAll('[br]', '<br />')

    str = str.replaceAll('[ul]', '<ul>')
    str = str.replaceAll('[/ul]', '</ul>')
    str = str.replaceAll('[ol]', '<ol>')
    str = str.replaceAll('[/ol]', '</ol>')
    str = str.replaceAll('[li]', '<li>')
    str = str.replaceAll('[/li]', '</li>')

    str = str.replaceAll('[center]', '<div style="text-align: center">')
    str = str.replaceAll('[/center]', '</div>')

    str = str.replaceAll('[url]', '<a class="link-url" href="')
    str = str.replaceAll('[text]', '" target="_blank">')
    str = str.replaceAll('[/url]', '</a>')
  }

  return str
}
