import axios from 'axios'

export default {
  namespaced: true,

  state: {
    rooms: []
  },

  getters: {
    getRooms: state => state.rooms,
    getAsInputOptions (context) {
      return context.rooms.map(item => ({ text: item.name, value: item.slug }))
    },
    getAsGameTestFormat (context) {
      const outputObj = {}
      context.rooms.forEach(item => {
        outputObj[item.slug] = {
          title: item.name,
          icon: item.icon ? item.icon : 'https://via.placeholder.com/80x80',
          always_show: item.always_show ? item.always_show : false,
          hide_score: item.hide_score ? item.hide_score : false
        }
      })

      return outputObj
    }
  },

  mutations: {
    sync: (state, payload) => {
        state.rooms = payload
    }
  },

  actions: {
    get: async (context) => {
      const args = {
        action: 'mshot_get_all_game_rooms',
        'game-slug': context.rootState.gameSetting.game_slug
      }
      const response = await axios.post(`${context.rootState.gameSetting.base_url}/moonshot-game`, args)
      context.commit('sync', response.data.data)
      console.log('syncbadges', response)
    },
    update: async (context, args) => {
      args.action = 'mshot_sync_game_rooms'
      args['game-slug'] = context.rootState.gameSetting.game_slug

      const response = await axios.post(`${context.rootState.gameSetting.base_url}/moonshot-game`, args)
      context.commit('sync', response.data)
      console.log('syncbadges', response)
    }
  }

}
