import axios from 'axios'

export default {
  namespaced: true,

  state: {
    badges: []
  },

  getters: {
    getBadges: state => state.badges,
    getAsOption: function (state) {
      return state.badges.map(badge => ({ text: badge.name, value: badge.slug }))
    },
    getFromSlug: function (state) {
      return function (slug) {
        return state.badges.find(obj => obj.slug === slug)
      }
    }
  },

  mutations: {
    syncBadges: (state, payload) => {
        state.badges = payload
    }
  },

  actions: {
    getBadges: async (context) => {
      const args = {
        action: 'mshot_get_all_game_badge',
        'game-slug': context.rootState.gameSetting.game_slug
      }
      const response = await axios.post(`${context.rootState.gameSetting.base_url}/moonshot-game`, args)
      context.commit('syncBadges', response.data.data)
    },
    updateBadges: async (context, args) => {
      args.action = 'mshot_sync_game_badge'
      args['game-slug'] = context.rootState.gameSetting.game_slug

      const response = await axios.post(`${context.rootState.gameSetting.base_url}/moonshot-game`, args)
      context.commit('syncBadges', response.data)
    }
  }

}
