export const state = {
  showChatLoading: false
}

export const getters = {
  getShowChatLoading: state => state.showChatLoading
}

export const mutations = {
  SET_CHAT_LOADING: (state, value) => {
    state.showChatLoading = value
  }
}

// export const actions = {
//   addTodo: (context, payload) => {
//     context.commit('ADD_TODO', payload)
//   }
// }
