import * as angel from '@/assets/emoji/angel.json'
import * as angry1 from '@/assets/emoji/angry1.json'
import * as angry2 from '@/assets/emoji/angry2.json'
import * as annoyed from '@/assets/emoji/annoyed.json'
import * as cool from '@/assets/emoji/cool.json'
import * as cry from '@/assets/emoji/cry.json'
import * as cute from '@/assets/emoji/cute.json'
import * as dead from '@/assets/emoji/dead.json'
import * as devil from '@/assets/emoji/devil.json'
import * as dizzy from '@/assets/emoji/dizzy.json'
import * as embarrassed from '@/assets/emoji/embarrassed.json'
import * as happy1 from '@/assets/emoji/happy1.json'
import * as happy2 from '@/assets/emoji/happy2.json'
import * as kiss from '@/assets/emoji/kiss.json'
import * as love1 from '@/assets/emoji/love1.json'
import * as love2 from '@/assets/emoji/love2.json'
import * as mean from '@/assets/emoji/mean.json'
import * as nasty from '@/assets/emoji/nasty.json'
import * as nevermind from '@/assets/emoji/nevermind.json'
import * as rock from '@/assets/emoji/rock.json'
import * as sad1 from '@/assets/emoji/sad1.json'
import * as sad2 from '@/assets/emoji/sad2.json'
import * as shady from '@/assets/emoji/shady.json'
import * as sick from '@/assets/emoji/sick.json'
import * as sigh from '@/assets/emoji/sigh.json'
import * as surprised from '@/assets/emoji/surprised.json'
import * as thinking from '@/assets/emoji/thinking.json'
import * as thumbsdown from '@/assets/emoji/thumbsdown.json'
import * as tongue from '@/assets/emoji/tongue.json'
import * as victory from '@/assets/emoji/victory.json'
import * as wink from '@/assets/emoji/wink.json'
import * as correct from '@/assets/emoji/correct.json'
import * as wrong from '@/assets/emoji/wrong.json'

const StickerSet = {
    angry1: angry1,
    angry2: angry2,
    angel: angel,
    annoyed: annoyed,
    cool: cool,
    cry: cry,
    cute: cute,
    dead: dead,
    devil: devil,
    dizzy: dizzy,
    embarrassed: embarrassed,
    happy1: happy1,
    happy2: happy2,
    kiss: kiss,
    love1: love1,
    love2: love2,
    mean: mean,
    nasty: nasty,
    nevermind: nevermind,
    rock: rock,
    sad1: sad1,
    sad2: sad2,
    shady: shady,
    sick: sick,
    sigh: sigh,
    surprised: surprised,
    thinking: thinking,
    thumbsdown: thumbsdown,
    tongue: tongue,
    victory: victory,
    wink: wink,
    correct: correct,
    wrong: wrong

}

export { StickerSet }

export default StickerSet
