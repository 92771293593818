import Vue from 'vue'
import Vuex from 'vuex'
import * as bubbleloading from '@/store/modules/bubbleloading.js'
import gameSetting from '@/store/modules/gameSetting.js'
import gamePlay from '@/store/modules/gamePlay.js'
import badges from '@/store/modules/badges.js'
import avatars from '@/store/modules/avatars.js'
import rooms from '@/store/modules/rooms.js'
import actors from '@/store/modules/actors.js'
// import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    bubbleloading,
    gameSetting,
    badges,
    avatars,
    rooms,
    actors,
    gamePlay
  },
  actions: {
    init: async (context) => {
      await context.dispatch('gameSetting/setBaseUrl')
      await context.dispatch('rooms/get')
      await context.dispatch('actors/get')
    }
  }
//   plugins: [createPersistedState()],
})
// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
