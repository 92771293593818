import axios from 'axios'

export default {
  namespaced: true,

  state: {
    avatars: []
  },

  getters: {
    getAvatars: state => state.avatars
  },

  mutations: {
    sync: (state, payload) => {
        state.avatars = payload
    }
  },

  actions: {
    get: async (context) => {
      const args = {
        action: 'mshot_get_all_game_avatars',
        'game-slug': context.rootState.gameSetting.game_slug
      }
      const response = await axios.post(`${context.rootState.gameSetting.base_url}/moonshot-game`, args)
      context.commit('sync', response.data.data)
    },
    update: async (context, args) => {
      args.action = 'mshot_sync_game_avatars'
      args['game-slug'] = context.rootState.gameSetting.game_slug

      const response = await axios.post(`${context.rootState.gameSetting.base_url}/moonshot-game`, args)
      context.commit('sync', response.data)
    }
  }

}
